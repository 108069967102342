const Auth = {
  mandatorySignIn: true,
  identityPoolRegion: "eu-central-1",
  region: "eu-central-1",
  identityPoolId: process.env.REACT_APP_IdentityPoolId,
  userPoolId: process.env.REACT_APP_UserPoolId,
  userPoolWebClientId: process.env.REACT_APP_UserPoolClientId,
};

const graphql = {
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GraphQlApiEndpointUrl,
};

const API = {
  endpoints: [
    {
      name: "restB",
      endpoint: process.env.REACT_APP_BWebRestApiBaseUrl,
      region: "eu-central-1",
    },
  ],
};

const aws_pubsub = {
  aws_pubsub_region: "eu-central-1",
  aws_pubsub_endpoint:
    "wss://a1lxmxii4hiasi-ats.iot.eu-central-1.amazonaws.com/mqtt",
};

export default {
  Auth,
  graphql,
  aws_pubsub,
  API,
};
