/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStatistics = /* GraphQL */ `
  query GetStatistics($input: StatisticsInput) {
    getStatistics(input: $input) {
      statistics {
        date
        energy
        chargingTime
        healthySess
        faultySess
      }
      nextToken
    }
  }
`;
export const listManufacturers = /* GraphQL */ `
  query ListManufacturers($count: Int, $nextToken: String) {
    listManufacturers(count: $count, nextToken: $nextToken) {
      manufacturers {
        manufacturerID
        manufacturerName
        customers
        admins
        manuals
        useEcoGImage
      }
      nextToken
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers($count: Int, $nextToken: String) {
    listCustomers(count: $count, nextToken: $nextToken) {
      customers {
        customerID
        customerName
        admins
      }
      nextToken
    }
  }
`;

export const getSshConnectedDevice = /* GraphQL */ `
  query getSshConnectedDevice($env: String!, $deviceId: String!) {
    getSshConnectedDevice(env: $env, deviceId: $deviceId) {
      connected
      name
      port
    }
  }
`;

export const listSshConnectedDevices = /* GraphQL */ `
  query ListSshConnectedDevices($env: String!, $deviceType: String!) {
    listSshConnectedDevices(env: $env, deviceType: $deviceType) {
      name
      port
      ip
      user
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers($role: Role) {
    listUsers(role: $role) {
      users {
        groupID
        email
        role
      }
      nextToken
    }
  }
`;
export const listSeccs = /* GraphQL */ `
  query ListSeccs($count: Int, $nextToken: String) {
    listSeccs(count: $count, nextToken: $nextToken) {
      seccs {
        hardwareType
        version
        serialNo
        attachedTo
        location
        exactLocation
        manufacturerID
        chargerID
        consolePass
        opkgPass
        seccID
        internalCheck
        checkDate
        shipment
        shipmentDate
        delivery
        deliveryDate
        customerTest
        testDate
        notes
        deleted
        facts
        sshCnx
        sshPort
        setup
        configpath
      }
      nextToken
    }
  }
`;
export const components = /* GraphQL */ `
  query Components($compType: String!) {
    components(compType: $compType)
  }
`;
export const listChargers = /* GraphQL */ `
  query ListChargers(
    $manufacturerID: String
    $count: Int
    $nextToken: String
    $filters: ChargerFilters
  ) {
    listChargers(
      manufacturerID: $manufacturerID
      count: $count
      nextToken: $nextToken
      filters: $filters
    ) {
      chargers {
        chargerID
        chargerTypeID
        customerID
        manufacturerID
        attachedTo
        siteID
        lastSessionID
        whitelistID
        tags
        coordinates
        address
        allowedGroups
        propertyValues
        lastService
        serviceDue
        seccSerialNo
        seccID
        swVersion
        hwVersion
        deleted
      }
      nextToken
    }
  }
`;
export const listChargerTypes = /* GraphQL */ `
  query ListChargerTypes($count: Int, $nextToken: String) {
    listChargerTypes(count: $count, nextToken: $nextToken) {
      chargerTypes {
        manufacturerID
        chargerTypeID
        serialNumber
        softwareVersion
        hardwareVersion
        propertyKeys
        deleted
      }
      nextToken
    }
  }
`;
export const getChargerType = /* GraphQL */ `
  query GetChargerType($chargerTypeID: String!) {
    getChargerType(chargerTypeID: $chargerTypeID) {
      manufacturerID
      chargerTypeID
      serialNumber
      softwareVersion
      hardwareVersion
      propertyKeys
      deleted
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $manufacturerID: String
    $startTime: AWSTimestamp!
    $count: Int
    $nextToken: String
    $filters: SessionFilters
  ) {
    listSessions(
      manufacturerID: $manufacturerID
      startTime: $startTime
      count: $count
      nextToken: $nextToken
      filters: $filters
    ) {
      sessions {
        sessionID
        chargerID
        customerID
        outletType
        outlet
        manufacturerID
        startTime
        endTime
        originStartTime
        energyConsumed
        chargerState
        ending
        seccID
        logfile
        errorCode
        errorMsg
        evmac
        evVendor
      }
      nextToken
    }
  }
`;
export const listSessionsByCharger = /* GraphQL */ `
  query ListSessionsByCharger(
    $chargerID: String!
    $startTime: AWSTimestamp!
    $count: Int
    $nextToken: String
  ) {
    listSessionsByCharger(
      chargerID: $chargerID
      startTime: $startTime
      count: $count
      nextToken: $nextToken
    ) {
      sessions {
        sessionID
        chargerID
        customerID
        outletType
        outlet
        manufacturerID
        startTime
        endTime
        originStartTime
        energyConsumed
        chargerState
        ending
        seccID
        logfile
        errorCode
        errorMsg
        evmac
        evVendor
      }
      nextToken
    }
  }
`;
export const listSessionsBySecc = /* GraphQL */ `
  query ListSessionsBySecc(
    $seccID: String!
    $startTime: AWSTimestamp!
    $count: Int
    $nextToken: String
  ) {
    listSessionsBySecc(
      seccID: $seccID
      startTime: $startTime
      count: $count
      nextToken: $nextToken
    ) {
      sessions {
        sessionID
        chargerID
        customerID
        outletType
        outlet
        manufacturerID
        startTime
        endTime
        originStartTime
        energyConsumed
        chargerState
        ending
        seccID
        logfile
        errorCode
        errorMsg
        evmac
        evVendor
      }
      nextToken
    }
  }
`;
export const getChargerInfo = /* GraphQL */ `
  query GetChargerInfo($chargerID: String!) {
    getChargerInfo(chargerID: $chargerID) {
      chargerID
      chargerTypeID
      customerID
      manufacturerID
      attachedTo
      siteID
      lastSessionID
      whitelistID
      tags
      coordinates
      address
      allowedGroups
      propertyValues
      lastService
      serviceDue
      seccSerialNo
      seccID
      swVersion
      hwVersion
      deleted
    }
  }
`;
export const getSeccInfo = /* GraphQL */ `
  query GetSeccInfo($serialNo: String!) {
    getSeccInfo(serialNo: $serialNo) {
      hardwareType
      version
      serialNo
      attachedTo
      location
      exactLocation
      manufacturerID
      chargerID
      consolePass
      opkgPass
      seccID
      internalCheck
      checkDate
      shipment
      shipmentDate
      delivery
      deliveryDate
      customerTest
      testDate
      notes
      deleted
      facts
      sshCnx
      sshPort
      setup
      configpath
    }
  }
`;
export const secc = /* GraphQL */ `
  query Secc($serialNo: String!) {
    secc(serialNo: $serialNo) {
      serialNo
      hardwareType
      chargerID
      manufacturerID
      readOnlyInfo
      attachedTo
      facts {
        versions {
          deviceType
          rootfs
        }
        timestamp
      }
      seccID
    }
  }
`;
export const component = /* GraphQL */ `
  query Component($compType: String!, $compID: String!) {
    component(compType: $compType, compID: $compID) {
      compType
      compID
      manufacturerID
      customerID
      facts
      setup
      location
      exactLocation
      notes
      rportSetup
      attachedTo
      deleted
      readOnlyInfo
      hardwareType
      hostname
    }
  }
`;
export const getChargerStatus = /* GraphQL */ `
  query GetChargerStatus($chargerID: String!) {
    getChargerStatus(chargerID: $chargerID) {
      chargerId
      connection
      connectionChange
    }
  }
`;
export const getChargerStates = /* GraphQL */ `
  query GetChargerStates {
    getChargerStates {
      states {
        id
        phs
        pilot
      }
      nextToken
    }
  }
`;
export const getStates = /* GraphQL */ `
  query GetStates($chargers: [String!]!) {
    getStates(chargers: $chargers)
  }
`;
export const listIssuesByCharger = /* GraphQL */ `
  query ListIssuesByCharger(
    $chargerID: String!
    $count: Int
    $nextToken: String
  ) {
    listIssuesByCharger(
      chargerID: $chargerID
      count: $count
      nextToken: $nextToken
    ) {
      issues {
        chargerID
        createdOn
        openedBy
        updatedOn
        title
        status
        description
        notifyServiceTeam
        issueID
        solvedAt
        attachments {
          type
          addedAt
          addedBy
          createdOn
          startTime
        }
      }
      nextToken
    }
  }
`;
export const listCommentsByCharger = /* GraphQL */ `
  query ListCommentsByCharger(
    $commentID: String!
    $count: Int
    $nextToken: String
  ) {
    listCommentsByCharger(
      commentID: $commentID
      count: $count
      nextToken: $nextToken
    ) {
      comments {
        commentID
        createdOn
        body
        writtenBy
        deleted
      }
      nextToken
    }
  }
`;
export const listFilesByCharger = /* GraphQL */ `
  query ListFilesByCharger(
    $chargerID: String!
    $count: Int
    $nextToken: String
  ) {
    listFilesByCharger(
      chargerID: $chargerID
      count: $count
      nextToken: $nextToken
    ) {
      files {
        chargerID
        createdOn
        description
        filename
        category
        uploadedBy
      }
      nextToken
    }
  }
`;
export const listLogsByCharger = /* GraphQL */ `
  query ListLogsByCharger(
    $chargerID: String!
    $count: Int
    $nextToken: String
  ) {
    listLogsByCharger(
      chargerID: $chargerID
      count: $count
      nextToken: $nextToken
    ) {
      logs {
        chargerID
        createdOn
        filename
        logType
        outletType
        seccID
      }
      nextToken
    }
  }
`;
export const getManufucturerInfo = /* GraphQL */ `
  query GetManufucturerInfo {
    getManufucturerInfo {
      manufacturerID
      manufacturerName
      customers
      admins
      manuals
      useEcoGImage
    }
  }
`;
export const getConfigs = /* GraphQL */ `
  query GetConfigs {
    getConfigs {
      chargerDetailsActions
      createChargerType
      manufacturers
      customers
    }
  }
`;
export const getSignedUrl = /* GraphQL */ `
  query GetSignedUrl($action: s3Action!, $s3Key: String!, $type: String) {
    getSignedUrl(action: $action, s3Key: $s3Key, type: $type)
  }
`;
export const listChargerAlerts = /* GraphQL */ `
  query ListChargerAlerts(
    $chargerID: String!
    $count: Int
    $nextToken: String
  ) {
    listChargerAlerts(
      chargerID: $chargerID
      count: $count
      nextToken: $nextToken
    ) {
      alerts {
        seccID
        chargerID
        createdOn
        manufacturerID
        customerID
        source
        message
      }
      nextToken
    }
  }
`;
export const listControllerAlerts = /* GraphQL */ `
  query ListControllerAlerts(
    $seccID: String!
    $count: Int
    $nextToken: String
  ) {
    listControllerAlerts(
      seccID: $seccID
      count: $count
      nextToken: $nextToken
    ) {
      alerts {
        seccID
        chargerID
        createdOn
        manufacturerID
        customerID
        source
        message
      }
      nextToken
    }
  }
`;
export const log = /* GraphQL */ `
  query Log($chargerID: String!, $createdOn: AWSTimestamp!) {
    log(chargerID: $chargerID, createdOn: $createdOn) {
      chargerID
      createdOn
      filename
      logType
      outletType
      seccID
    }
  }
`;
export const issue = /* GraphQL */ `
  query Issue($chargerID: String!, $createdOn: AWSTimestamp!) {
    issue(chargerID: $chargerID, createdOn: $createdOn) {
      chargerID
      createdOn
      openedBy
      updatedOn
      title
      status
      description
      notifyServiceTeam
      issueID
      solvedAt
      attachments {
        type
        addedAt
        addedBy
        createdOn
        startTime
      }
    }
  }
`;
export const session = /* GraphQL */ `
  query Session($chargerID: String!, $startTime: AWSTimestamp!) {
    session(chargerID: $chargerID, startTime: $startTime) {
      sessionID
      chargerID
      customerID
      outletType
      outlet
      manufacturerID
      startTime
      endTime
      originStartTime
      energyConsumed
      chargerState
      ending
      seccID
      logfile
      errorCode
      errorMsg
      evmac
      evVendor
    }
  }
`;
export const issues = /* GraphQL */ `
  query Issues($manufacturerID: String, $count: Int, $nextToken: String) {
    issues(
      manufacturerID: $manufacturerID
      count: $count
      nextToken: $nextToken
    ) {
      issues {
        chargerID
        createdOn
        openedBy
        updatedOn
        title
        status
        description
        notifyServiceTeam
        issueID
        solvedAt
        attachments {
          type
          addedAt
          addedBy
          createdOn
          startTime
        }
      }
      nextToken
    }
  }
`;
export const alerts = /* GraphQL */ `
  query Alerts($manufacturerID: String, $count: Int, $nextToken: String) {
    alerts(
      manufacturerID: $manufacturerID
      count: $count
      nextToken: $nextToken
    ) {
      alerts {
        seccID
        chargerID
        createdOn
        manufacturerID
        customerID
        source
        message
      }
      nextToken
    }
  }
`;
export const alert = /* GraphQL */ `
  query Alert($chargerID: String!, $createdOn: AWSTimestamp!) {
    alert(chargerID: $chargerID, createdOn: $createdOn) {
      seccID
      chargerID
      createdOn
      manufacturerID
      customerID
      source
      message
    }
  }
`;
export const ctrlSetupConfig = /* GraphQL */ `
  query CtrlSetupConfig {
    ctrlSetupConfig
  }
`;
export const getIoTcred = /* GraphQL */ `
  query GetIoTcred {
    getIOTcred
  }
`;
export const dummy = /* GraphQL */ `
  query Dummy {
    dummy
  }
`;
