import { TOGGLE_MAPVIEW, SET_SELECTION, CLEAR_SELECTION } from "../actionTypes";

const initialState = {
  chargers: {
    mapActive: true,
  },
  chargerDetails: {
    selectedCharger: undefined,
  },
  seccDetails: {
    seccCharger: undefined,
  },
  compDetails: {
    selectedAppboard: undefined,
  },
  logs: {
    singleLogRecord: undefined,
    singleLogActive: false,
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MAPVIEW: {
      return {
        ...state,
        chargers: {
          ...state.chargers,
          mapActive: !state.chargers.mapActive
        }
      }
    }
    case SET_SELECTION: {
      const { area, field, selection } = action.payload;
      return {
        ...state,
        [area]: {
          ...state[area],
          [field]: selection
        }
      }
    }
    case CLEAR_SELECTION: {
      const { area, field } = action.payload;
      return {
        ...state,
        [area]: {
          ...state[area],
          [field]: undefined
        }
      }
    }
    default:
      return state;
  }
}
