import React, { Component } from "react";
import {
  Layout,
  Avatar,
  Icon,
  Badge,
  Tag,
  Tooltip,
  Dropdown,
  Menu,
  Button,
  notification,
  Row,
  Col,
} from "antd";
import cookie from "react-cookies";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import Amplify from "@aws-amplify/core";
import EcogLifeC from "./assets/ecog_logo.png";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import * as queries from "./graphql/queries.js";
import SideMenu from "./pages/Main";
import aws_config from "./aws.config";
import lifec from "./assets/ecog_logo.png";
import "antd/dist/antd.less";
import "./css/App.scss";
import { connect } from "react-redux";
import { setData } from "./redux/actions";

const { Header } = Layout;

Amplify.configure({
  ...aws_config.graphql,
  Auth: aws_config.Auth,
  API: {
    ...aws_config.API,
    graphql_headers: async () => {
      return {
        Authorization: (await Auth.currentSession()).idToken.jwtToken,
      };
    },
  },
});

Amplify.addPluggable(new AWSIoTProvider(aws_config.aws_pubsub));

const menu = (groups, crrGroup) =>
  groups && (
    <Menu onClick={changeGroup}>
      {groups
        .filter((e) => e !== crrGroup)
        .map((group) => {
          let gr = group.split("_");
          let companyType =
            gr[0] === "m" ? "Manufacturer" : gr[0] === "c" ? "Customer" : "__";
          let company = gr[1];
          let role = gr[gr.length - 1];
          let color =
            companyType === "Manufacturer"
              ? "var(--dark-magic)"
              : companyType === "Customer"
              ? "var(--kelp)"
              : "var(--dark-fog)";
          return (
            <Menu.Item key={group}>
              <span>
                <Tag color={color}>
                  {companyType === "__"
                    ? `EcoG  | ${role}`
                    : `${companyType} | ${company} | ${role}`}
                </Tag>
              </span>
            </Menu.Item>
          );
        })}
    </Menu>
  );
const changeGroup = (e) => {
  cookie.save("crrGroup", e.key, { path: "/", maxAge: 2592000 }); // expires in 1 month
  window.location.reload();
};

class App extends Component {
  state = {
    newVersionAvailable: false,
    newVersionWaitingCookie: false,
    notificationKey: undefined,
    waitingWorker: {},
    pollingInterval: undefined,
  };

  onServiceWorkerUpdate = (registration) => {
    console.log("registration ", registration);
    this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
    cookie.save("versionAvailable", "true", { path: "/", maxAge: 2592000 }); // expires in 1 month
  };

  updateServiceWorker = () => {
    const { waitingWorker } = this.state;
    console.log("waiting worker", this.state);
    if (waitingWorker && Object.keys(waitingWorker).length !== 0) {
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
    }
    this.setState({ newVersionAvailable: false, notificationKey: null });
    cookie.remove("versionAvailable");
    this.setState({ newVersionWaitingCookie: false });
    window.location.reload();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.newVersionWaitingCookie && !this.state.notificationKey) {
      // show snackbar with refresh button
      const key = `open${Date.now()}`;
      this.setState({ notificationKey: key });
      const btn = (
        <Button type="default" size="small" onClick={this.updateServiceWorker}>
          Refresh
        </Button>
      );
      notification.open({
        message: "A new version was released!",
        placement: "bottomLeft",
        duration: 0,
        closeIcon: <div></div>,
        btn,
        style: {
          width: "100%",
          background: "blue",
          borderRadius: 0,
          marginBottom: 0,
        },
        key,
        onClose: this.updateServiceWorker,
      });
    }

    if (!this.state.newVersionWaitingCookie && this.state.notificationKey) {
      window.location.reload();
    }
  }

  componentDidMount() {
    this.bootCredentials();
    this.setState({
      interval: setInterval(() => {
        let ver = cookie.load("versionAvailable");
        if (ver === "true") {
          this.setState({ newVersionWaitingCookie: true });
        } else {
          this.setState({ newVersionWaitingCookie: false });
        }
      }, 2000),
    });

    if (process.env.NODE_ENV === "production") {
      serviceWorkerRegistration.register({
        onUpdate: (registration) => {
          this.onServiceWorkerUpdate(registration);
        },
      });
    }
  }

  componentWillUnmount() {
    this.state.pollingInterval && clearInterval(this.pollingInterval);
  }

  async bootCredentials() {
    let authDetails = await getAuthDetails();
    if (!authDetails) return;
    this.props.setData("authDetails", authDetails);
    Amplify.configure({
      API: {
        ...aws_config.API,
        endpoints: [
          {
            ...aws_config.API.endpoints[0],
            custom_header: async () => {
              return {
                Authorization: (await Auth.currentSession()).idToken.jwtToken,
                group: authDetails.crrGroup,
              };
            },
          },
        ],
        graphql_headers: async () => {
          return {
            Authorization: (await Auth.currentSession()).idToken.jwtToken,
            group: authDetails.crrGroup,
          };
        },
      },
    });

    let configs = await getCustomConfigs();
    this.props.setData("configs", configs.data.getConfigs || {});

    if (authDetails.companyType === "__") {
      let res = await API.graphql(graphqlOperation(queries.listManufacturers));
      this.props.setData(
        "manufacturers",
        res.data.listManufacturers.manufacturers
      );
    } else {
      const res = await API.graphql(
        graphqlOperation(queries.getManufucturerInfo)
      );
      this.props.setData("manufacturerInfo", res.data.getManufucturerInfo);
    }
  }

  logout = async () => {
    await Auth.signOut();
    window.location.reload();
  };

  render() {
    const { companyType, company, role, email, groups, crrGroup } =
      this.props.authDetails;
    let color =
      companyType === "Manufacturer"
        ? "var(--dark-magic)"
        : companyType === "Customer"
        ? "var(--kelp)"
        : "var(--dark-fog)";
    return (
      <AmplifyAuthenticator
        handleAuthStateChange={(state) => {
          if (state === "signedin") {
            this.bootCredentials();
          }
        }}
      >
        <Row
          slot="sign-in"
          type="flex"
          align="middle"
          justify="space-between"
          gutter={[48, 16]}
          className="bg-fog"
        >
          <Col
            sm={24}
            md={24}
            lg={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={EcogLifeC} id="login-image" alt="EcoG LifeC logo"></img>
          </Col>
          <Col sm={24} md={24} lg={12}>
            <AmplifySignIn
              headerText="Login"
              usernameAlias="email"
              hideSignUp={true}
              style={{ textAlign: "center" }}
            ></AmplifySignIn>
          </Col>
        </Row>
        {email && (
          <Layout style={{ minHeight: "100vh" }}>
            <Header
              style={{
                position: "fixed",
                background: "var(--bright-fog)",
                padding: "0 10",
                boxShadow: "0 1px 4px rgba(0,21,41,.08)",
                zIndex: "999",
                width: "100%",
                height: "75px",
              }}
            >
              <img
                className="logo"
                src={lifec}
                id="lifec_logo_header"
                alt="EcoG logo"
                style={{
                  height: "100%",
                  width: "auto",
                }}
              />
              <div className="right-group">
                <Dropdown
                  overlay={menu(groups, crrGroup)}
                  overlayStyle={
                    groups && groups.length <= 1 ? { display: "none" } : {}
                  }
                >
                  <span
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {
                      <Tag color={color}>
                        {companyType === "__"
                          ? `EcoG | ${role}`
                          : `${companyType} | ${company} | ${role}`}
                      </Tag>
                    }
                  </span>
                </Dropdown>
                <Badge count={0} className="right-group-item">
                  <Avatar shape="circle" icon="user" />
                </Badge>
                <span size="large" icon="user" className="right-group-item">
                  {email}
                </span>
                {/* <Icon
              type="global"
              style={{ fontSize: "large" }}
              className="right-group-item"
            /> */}
                <Tooltip title="Logout" placement="bottom">
                  <Icon
                    type="logout"
                    style={{ fontSize: "large" }}
                    className="right-group-item"
                    onClick={() => this.logout()}
                  />
                </Tooltip>
              </div>
            </Header>
            <SideMenu />
          </Layout>
        )}
      </AmplifyAuthenticator>
    );
  }
}

/**
 * @typedef {Object} AuthDetails
 * @property {string} email - email of the user
 * @property {string} crrGroup - current cognito group of the user
 * @property {array}  groups - cognito groups of the user
 * @property {string} role - role of the user (admin/user)
 * @property {string} company - company name of the user
 * @property {string} companyType - company type of the user (manufacturer/customer)
 */

/**
 * Get Auth details of the current user from the token
 * @returns {AuthDetails} Auth details of the user
 */
const getAuthDetails = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) return null;
  const crrCookieGroup = cookie.load("crrGroup");

  let email = user.attributes.email;
  let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  let crrGroup;

  if (crrCookieGroup && groups.includes(crrCookieGroup)) {
    crrGroup = crrCookieGroup;
  } else {
    crrGroup = user.signInUserSession.accessToken.payload["cognito:groups"][0];
    cookie.save("crrGroup", crrGroup, { path: "/", maxAge: 2592000 }); // expires in 1 month
  }

  let temp = crrGroup.split("_");
  let companyType =
    temp[0] === "m" ? "Manufacturer" : temp[0] === "c" ? "Customer" : "__";
  let role = temp[temp.length - 1];
  let company = temp[1];

  return { crrGroup, email, role, company, companyType, groups };
};

const getCustomConfigs = async () => {
  let res = await API.graphql(graphqlOperation(queries.getConfigs));
  return res;
};

const mapStateToProps = (state) => {
  const authDetails = state.metadata.authDetails.data;
  return { authDetails };
};

export default connect(mapStateToProps, { setData })(App);
