import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";

if (process.env.NODE_ENV !== "development") console.log = () => {};

// disable warnings from react-google-maps
const consoleWarn = console.warn;
const SUPPRESSED_WARNINGS = [
  "Warning: React.createFactory()",
  "Warning: componentWillMount has been renamed",
  "Google Maps JavaScript API",
  "google.maps.Marker is deprecated",
  "google.maps.event.addDomListener()",
  "[Intervention] Slow network is detected.",
  "Warning: Can't perform a React state update on an unmounted component.",
];

console.warn = function filterWarnings(msg, ...args) {
  if (
    !SUPPRESSED_WARNINGS.some(
      (entry) =>
        (typeof msg === "string" || msg instanceof String) &&
        msg?.includes(entry)
    )
  ) {
    consoleWarn(msg, ...args);
  }
};

const consoleError = console.error;
const SUPPRESSED_ERRORS = [
  "Warning: [antd: Tabs] `type=card|editable-card`", // https://github.com/ant-design/ant-design/issues/22637
  "Warning: `defaultValue` is invalid for `getFieldDecorator`",
  "Warning: [antd: Icon] Should have `type` prop or `component` prop or `children`.",
  "Warning: Can't perform a React state update on an unmounted component.",
];

console.error = function filterErrors(msg, ...args) {
  if (
    !SUPPRESSED_ERRORS.some(
      (entry) =>
        (typeof msg === "string" || msg instanceof String) &&
        msg?.includes(entry)
    )
  ) {
    consoleError(msg, ...args);
  }
  return;
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// SW Registeration is done in App.js
