import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Layout, Menu, Icon } from "antd";
import { Route, Link, Switch, withRouter, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

const { Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

// Routes Array
let routes = [
  // {
  //   allowRole: ["admin"],
  //   allowType: ["__"],
  //   name: "customers",
  //   path: "/customers",
  //   exact: false,
  //   main: lazy(() =>
  //     import(
  //       /* webpackChunkName: "CustomersView" */ "./customers/CustomersView"
  //     )
  //   ),
  // },
  {
    allowRole: ["admin"],
    allowType: ["manufacturer"],
    name: "customers",
    path: "/customers",
    exact: false,
    main: lazy(() =>
      import(
        /* webpackChunkName: "CustomersView" */ "./customers/CustomersView"
      )
    ),
  },
  {
    allowRole: ["admin"],
    allowType: ["customer", "manufacturer", "__"],
    name: "users",
    path: "/users",
    exact: false,
    main: lazy(() =>
      import(/* webpackChunkName: "UsersView" */ "./users/UsersView")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    name: "chargers",
    path: "/chargers",
    exact: false,
    main: lazy(() =>
      import(/* webpackChunkName: "MapView" */ "./chargers/MapView")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    name: "charger-details",
    path: "/charger-details",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "ChargerDetailsView" */ "./chargerDetails/ChargerDetailsView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    name: "charger-details",
    path: "/charger-details/:chargerId",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "ChargerDetailsView" */ "./chargerDetails/ChargerDetailsView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    name: "charger-details-statistics",
    path: "/charger-details/:chargerId/statistics",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "ChargerDetailsView" */ "./chargerDetailsMonitoring/index"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer"],
    name: "sessions",
    path: "/sessions",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "SessionsComponent" */ "./sessions/SessionsComponent"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    name: "sessions",
    path: "/sessions",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "SessionsComponent" */ "./sessions/ecogOrg/SessionsComponent"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    name: "issues",
    path: "/issues",
    exact: false,
    main: lazy(() =>
      import(/* webpackChunkName: "IssuesView" */ "./issues/IssuesView")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    name: "alerts",
    path: "/alerts",
    exact: false,
    main: lazy(() =>
      import(/* webpackChunkName: "AlertsView" */ "./alerts/AlertsView")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer"],
    name: "chargerTypes",
    path: "/charger-types",
    exact: true,
    main: lazy(() => import("./chargerTypes/ChargerTypesView")),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer"],
    name: "chargerTypes",
    path: "/charger-types/:typeId",
    exact: true,
    main: lazy(() => import("./chargerTypes/ChargerTypeDetails")),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer"],
    name: "createCharger",
    path: "/create-charger",
    exact: false,
    main: lazy(() =>
      import(
        /* webpackChunkName: "CreateChargerView" */ "./createCharger/CreateChargerView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    name: "controllers",
    path: "/controllers",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "ControllersView" */ "./controllers/ControllersView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__", "manufacturer"],
    name: "appboards",
    path: "/appboards",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "AppboardsView" */ "./appboards/AppboardsView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__", "manufacturer"],
    name: "appboards",
    path: "/appboards/:boardId",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "AppboardDetailsView" */ "./appboardDetails/AppboardDetailsView"
      )
    ),
  },

  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    name: "cppp2300",
    path: "/cppp2300",
    exact: true,
    main: lazy(() =>
      import(/* webpackChunkName: "Cppp2300View" */ "./cppp2300/Cppp2300View")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    name: "cppp2300",
    path: "/cppp2300/:serialNo",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "cppp2300DetailsView" */ "./cppp2300Details/Cppp2300DetailsView"
      )
    ),
  },

  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    name: "displays",
    path: "/displays",
    exact: true,
    main: lazy(() =>
      import(/* webpackChunkName: "DisplaysView" */ "./displays/DisplaysView")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    name: "displays",
    path: "/displays/:serialNo",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "DisplayDetailsView" */ "./displayDetails/DisplayDetailsView"
      )
    ),
  },

  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer"],
    name: "controllers",
    path: "/controllers",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "ControllersView" */ "./controllers/controllersManufacturer/ControllersView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer", "customer"],
    name: "controllers",
    path: "/controllers/:seccId",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "SingleControllerView" */ "./controllers/controllersManufacturer/SingleControllerView"
      )
    ),
  },

  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer"],
    name: "converters",
    path: "/converters",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "ConvertersView" */ "./converters/ConvertersView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer"],
    name: "converters",
    path: "/converters/:boardId",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "SingleConverterView" */ "./converterDetails/ConverterDetailsView"
      )
    ),
  },

  // { allowRole: ["admin" , "user"],allowType: ["__", "manufacturer"], name: "converters", path: "/converters",exact: true, main: ()=><span>UNDER DEVELOPMENT</span> },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    name: "seccDetails",
    path: "/controllers/:seccId",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "SeccDetailsView" */ "./seccDetails/SeccDetailsView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    name: "manufacturers",
    path: "/manufacturers",
    exact: false,
    main: lazy(() =>
      import(
        /* webpackChunkName: "ManufacturersView" */ "./manufacturers/ManufacturersView"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__", "manufacturer"],
    path: "/firmware-portal",
    exact: true,
    main: lazy(() =>
      import(/* webpackChunkName: "FirmwarePortal" */ "./firmwarePortal/index")
    ),
  },
  {
    allowRole: ["admin"],
    allowType: ["__", "manufacturer"],
    path: "/generate-api-key",
    exact: true,
    main: lazy(() =>
      import(/* webpackChunkName: "FirmwarePortal" */ "./generateAPIKey/index")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    path: "/global",
    exact: true,
    main: lazy(() =>
      import(/* webpackChunkName: "Global" */ "./statistics/Global")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    path: "/per-controller",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "PerController" */ "./statistics/PerController"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    path: "/per-customer",
    exact: true,
    main: lazy(() =>
      import(/* webpackChunkName: "PerCustomer" */ "./statistics/PerCustomer")
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    path: "/create-production-order",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "CreateProductionOrder" */ "./deliveries/CreateProductionOrder"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    path: "/list-production-order",
    exact: true,
    main: lazy(() =>
      import(
        /* webpackChunkName: "ListProductionOrder" */ "./deliveries/ListProductionOrder"
      )
    ),
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["__", "manufacturer", "customer"],
    path: "/",
    main: () => <Redirect to="chargers" />,
  },
  // {
  //   allowRole: ["admin", "user"],
  //   allowType: ["__"],
  //   name: "createSecc",
  //   path: "/create-secc",
  //   exact: false,
  //   main: lazy(() =>
  //     import(
  //       /* webpackChunkName: "CreateSeccView" */ "./createSecc/CreateSeccView"
  //     )
  //   ),
  // },
  // {
  //   allowRole: ["admin", "user"],
  //   allowType: ["manufacturer"],
  //   name: "createChargerType",
  //   path: "/create-charger-type",
  //   exact: false,
  //   main: lazy(() =>
  //     import(
  //       /* webpackChunkName: "CreateChargerTypeView" */ "./createChargerType/CreateChargerTypeView"
  //     )
  //   ),
  // },
  // {
  //   allowRole: ["admin", "user"],
  //   allowType: ["__"],
  //   name: "seccDetails",
  //   path: "/secc-details",
  //   exact: true,
  //   main: lazy(() =>
  //     import(
  //       /* webpackChunkName: "SeccDetailsView" */ "./seccDetails/SeccDetailsView"
  //     )
  //   ),
  // },
];
let menuItems = [
  {
    allowRole: ["admin", "user"],
    allowType: ["__"],
    title: "Manufacturers",
    name: "manufacturers",
    key: "manufacturers",
    icon: "thunderbolt",
    link: "/manufacturers",
  },
  {
    allowRole: ["admin"],
    allowType: ["manufacturer"],
    title: "Customers",
    name: "customers",
    key: "customers",
    icon: "team",
    link: "/customers",
  },
  {
    allowRole: ["admin"],
    allowType: ["customer", "manufacturer", "__"],
    title: "Users",
    name: "users",
    key: "users",
    icon: "team",
    link: "/users",
  },
  {
    allowRole: ["admin"],
    allowType: ["__", "manufacturer"],
    title: "Generate API Key",
    name: "generateAPIKey",
    key: "generate-api-key",
    icon: "bars",
    link: "/generate-api-key",
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    title: "Chargers",
    name: "chargers",
    key: "chargers",
    icon: "thunderbolt",
    link: "/chargers",
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    title: "Charger details",
    name: "chargerDetails",
    key: "charger-details",
    icon: "appstore",
    link: "/charger-details",
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    title: "Sessions",
    name: "sessions",
    key: "sessions",
    icon: "bars",
    link: "/sessions",
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    title: "Issues",
    name: "issues",
    key: "issues",
    icon: "bars",
    link: "/issues",
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["customer", "manufacturer", "__"],
    title: "Alerts",
    name: "alerts",
    key: "alerts",
    icon: "bars",
    link: "/alerts",
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer"],
    title: "Create Charger",
    name: "createCharger",
    key: "create-charger",
    icon: "plus",
    link: "/create-charger",
  },
  {
    allowRole: ["admin", "user"],
    allowType: ["manufacturer"],
    title: "Charger Types",
    name: "chargerTypes",
    key: "charger-types",
    icon: "bars",
    link: "/charger-types",
  },
  // {
  //   allowRole: ["admin", "user"],
  //   allowType: ["manufacturer"],
  //   title: "Create Charger Type",
  //   name: "createChargerType",
  //   key: "create-charger-type",
  //   icon: "plus",
  //   link: "/charger-types?",
  // },
  // {
  //   allowRole: ["admin", "user"],
  //   allowType: ["manufacturer"],
  //   title: "Controllers",
  //   name: "controllers",
  //   key: "controllers",
  //   icon: "thunderbolt",
  //   link: "/controllers",
  // },
  // {
  //   allowRole: ["admin", "user"],
  //   allowType: ["__"],
  //   title: "SECC Details",
  //   name: "seccDetails",
  //   key: "secc-details",
  //   icon: "appstore",
  //   link: "/secc-details",
  // },
  // {
  //   allowRole: ["admin", "user"],
  //   allowType: ["__"],
  //   title: "Create SECC",
  //   name: "createSecc",
  //   key: "create-secc",
  //   icon: "plus",
  //   link: "/create-secc",
  // },
  {
    allowRole: ["admin", "user"],
    allowType: ["__", "manufacturer"],
    title: "Firmware Portal",
    name: "fimwarePortal",
    key: "firmware-portal",
    icon: "bars",
    link: "/firmware-portal",
  },
  {
    hasSubItems: true,
    allowRole: ["admin", "user"],
    allowType: ["__", "manufacturer"],
    title: "Components",
    name: "components",
    key: "components",
    icon: "build",
    subItems: [
      {
        allowRole: ["admin", "user"],
        allowType: ["__", "manufacturer"],
        title: "Controllers",
        name: "controllers",
        key: "controllers",
        icon: "codepen",
        link: "/controllers",
      },
      {
        allowRole: ["admin", "user"],
        allowType: ["__", "manufacturer"],
        title: "App Boards",
        name: "appboards",
        key: "appboards",
        icon: "code-sandbox",
        link: "/appboards",
      },
      {
        allowRole: ["admin", "user"],
        allowType: ["__"],
        title: "CPPP 2300",
        name: "cppp2300",
        key: "cppp2300",
        icon: "codepen",
        link: "/cppp2300",
      },
      {
        allowRole: ["admin", "user"],
        allowType: ["__"],
        title: "Converters",
        name: "converters",
        key: "converters",
        icon: "container",
        link: "/converters",
      },
      {
        allowRole: ["admin", "user"],
        allowType: ["__"],
        title: "Displays",
        name: "displays",
        key: "displays",
        icon: "desktop",
        link: "/displays",
      },
      // {
      //   allowRole: ["admin", "user"],
      //   allowType: ["__", "manufacturer"],
      //   title: "Converters",
      //   name: "converters",
      //   key: "converters",
      //   icon: "thunderbolt",
      //   link: "/converters",
      // },
    ],
  },
  {
    hasSubItems: true,
    allowRole: ["admin", "user"],
    allowType: ["__"],
    title: "Statistics",
    name: "statistics",
    key: "statistics",
    icon: "bars",
    subItems: [
      {
        allowRole: ["admin", "user"],
        allowType: ["__"],
        title: "Global",
        name: "global",
        key: "global",
        icon: "codepen",
        link: "/global",
      },
      {
        allowRole: ["admin", "user"],
        allowType: ["__"],
        title: "Per Customer",
        name: "per-customer",
        key: "per-customer",
        icon: "codepen",
        link: "/per-customer",
      },
      {
        allowRole: ["admin", "user"],
        allowType: ["__"],
        title: "Per Controller",
        name: "per-controller",
        key: "per-controller",
        icon: "codepen",
        link: "/per-controller",
      },
    ],
  },
  {
    hasSubItems: true,
    allowRole: ["admin", "user"],
    allowType: ["__"],
    title: "Deliveries",
    name: "deliveries",
    key: "deliveries",
    icon: "build",
    subItems: [
      {
        allowRole: ["admin", "user"],
        allowType: ["__"],
        title: "Create Production Order",
        name: "createProductionOrder",
        key: "create-production-order",
        icon: "codepen",
        link: "/create-production-order",
      },
      {
        allowRole: ["admin", "user"],
        allowType: ["__"],
        title: "List Production Order",
        name: "listProductionOrder",
        key: "list-production-order",
        icon: "codepen",
        link: "/list-production-order",
      },
    ],
  },
];

const filterFunc = (role, companyType) => (e) =>
  e.allowType.includes(companyType.toLowerCase()) &&
  e.allowRole.includes(role.toLowerCase());

const filterByConfigs = (configs, routes, menuItems) => {
  if (!configs) return { routes, menuItems };
  Object.keys(configs).forEach((cfg) => {
    if (configs[cfg] === false) {
      routes = routes.filter((e) => e.name !== cfg);
      menuItems = menuItems.filter((e) => e.name !== cfg);
    }
  });
  return { routes, menuItems };
};

const Main = (props) => {
  const {
    authDetails: {
      data: { role, companyType },
    },
    configs: { data: configs },
  } = useSelector((state) => state.metadata);
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(200);

  let routesConfigFiltered = filterByConfigs(configs, routes, menuItems);
  let localRoutes = routesConfigFiltered.routes.filter(
    filterFunc(role, companyType)
  );
  let localMenuItems = routesConfigFiltered.menuItems.filter(
    filterFunc(role, companyType)
  );

  const startResizing = useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          mouseMoveEvent.clientX -
            sidebarRef.current.getBoundingClientRect().left
        );
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  if (!companyType || !configs) {
    return "loading";
  }

  return (
    companyType && (
      <Layout>
        {/* Sider Started */}
        <StyledSider>
          {/* Menu Started */}
          <div
            style={{
              width: sidebarWidth,
            }}
            ref={sidebarRef}
          >
            <StyledMenu
              selectedKeys={[props.location.pathname.split("/")[1]]}
              mode="inline"
              theme="light"
              defaultOpenKeys={["components"]}
              onMouseDown={(e) => {
                stopResizing();
              }}
            >
              {localMenuItems.map((m) =>
                m.hasSubItems ? (
                  <StyledSubMenu
                    key={m.key}
                    title={
                      <span>
                        <Icon type={m.icon} />
                        <span>{m.title}</span>
                      </span>
                    }
                  >
                    {m.subItems
                      .filter(filterFunc(role, companyType))
                      .map((sub) => (
                        <Menu.Item key={sub.key}>
                          <Icon type={sub.icon} />
                          <span>{sub.title}</span>
                          <Link to={sub.link} />
                        </Menu.Item>
                      ))}
                  </StyledSubMenu>
                ) : (
                  <Menu.Item key={m.key}>
                    <Icon type={m.icon} />
                    <span>{m.title}</span>
                    <Link to={m.link} />
                  </Menu.Item>
                )
              )}
            </StyledMenu>
          </div>
        </StyledSider>
        <Resizer left={sidebarWidth} onMouseDown={startResizing} />
        <Layout
          style={{
            marginLeft: isResizing ? sidebarWidth + 3 : sidebarWidth,
            backgroundColor: "var(--fog)",
          }}
        >
          <Content>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {localRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.main}
                  />
                ))}
              </Switch>
            </Suspense>
          </Content>
          <Footer
            style={{ textAlign: "center", backgroundColor: "var(--fog)" }}
          >
            Powered by EcoG © 2021{" "}
          </Footer>
        </Layout>
      </Layout>
    )
  );
};

export default withRouter(Main);

const StyledSubMenu = styled(SubMenu)`
  .ant-menu-submenu-title:hover {
    color: var(--dark-kelp) !important;

    .ant-menu-submenu-arrow {
      &::before,
      &::after {
        background: var(--dark-kelp) !important;
      }
    }
  }

  .ant-menu-submenu-selected {
    color: var(--dark-kelp) !important;
  }
  .ant-menu-sub {
    background: var(--bright-fog) !important;

    .ant-menu-item {
      background: var(--bright-fog) !important;
    }
    .ant-menu-item-selected {
      background: var(--kelp) !important;
      color: var(--fog) !important;

      span,
      i {
        color: var(--fog) !important;
      }

      ::after {
        border-right: 3px solid var(--fog) !important;
      }
    }
  }
`;

const StyledMenu = styled(Menu)`
  background-color: var(--bright-fog) !important;
  padding-bottom: 75px;

  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover,
  .ant-menu-item > a:hover {
    color: var(--fog) !important;
  }

  .ant-menu-item:hover {
    color: var(--dark-kelp) !important;
  }

  .ant-menu-submenu-selected {
    color: var(--dark-kelp) !important;
  }

  .ant-menu-item-selected {
    background-color: var(--kelp) !important;

    span,
    i {
      color: var(--fog) !important;
    }

    ::after {
      border-right: 3px solid var(--fog) !important;
    }
  }
`;

const Resizer = styled.div`
  width: 2px;
  background-color: var(--bright-fog);
  cursor: col-resize;
  resize: horizontal;
  position: fixed;
  top: 0;
  left: ${(props) => props.left}px;
  bottom: 0;

  &:hover {
    width: 5px;
    background-color: var(--dark-fog);
  }
`;

const StyledSider = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  background-color: var(--bright-fog);
  max-width: unset !important;
  min-width: unset !important;
  width: auto !important;
  flex-basis: auto !important;
`;
