import { ADD_TODO, TOGGLE_TODO, SET_FILTER, SET_DATA, TOGGLE_MAPVIEW, SET_SELECTION, CLEAR_SELECTION, CLEAR_DATA } from "./actionTypes";

let nextTodoId = 0;

export const addTodo = content => ({
  type: ADD_TODO,
  payload: {
    id: ++nextTodoId,
    content
  }
});

export const toggleMapView = _ => ({
  type: TOGGLE_MAPVIEW
});

export const toggleTodo = id => ({
  type: TOGGLE_TODO,
  payload: { id }
});

export const setFilter = (area, filters) => ({
  type: SET_FILTER,
  payload: { area, filters }
});

export const setData = (area, data) => ({
  type: SET_DATA,
  payload: { area, data }
});

export const clearData = (area) => ({
  type: CLEAR_DATA,
  payload: { area }
});

export const setSelection = (area, field, selection) => ({
  type: SET_SELECTION,
  payload: { area, field, selection }
});

export const clearSelection = (area, field) => ({
  type: CLEAR_SELECTION,
  payload: { area, field }
});