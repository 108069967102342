import { ADD_TODO, TOGGLE_TODO, SET_DATA, SET_FILTER, CLEAR_DATA } from "../actionTypes";

const initialState = {
  chargers: {
    data: [],
    filters: {}
  },
  sessions: {
    data: [],
    filters: {}
  },
  issues: {
    data: [],
    filters: {}
  },
  alerts: {
    data: [],
    filters: {}
  },
  chargerStates: {
    data: {},
  },
  dailyStatistics: {
    data: undefined,
  },
  chargerTypes: {
    data: undefined,
  },
  manufacturerInfo: {
    data: undefined,
  },
  seccs: {
    data: undefined,
  },
  manufacturers: {
    data: [],
    filters: {}
  },
  appboards: {
    data: undefined,
    filters: {}
  },
  converters: {
    data: undefined,
    filters: {}
  },
  displays: {
    data: undefined,
    filters: {}
  },
  cppp2300s: {
    data: undefined,
    filters: {}
  },
  customers: {
    data: undefined,
    filters: {}
  },
  chargerDetails: {
    data: undefined,
  },
  compDetails: {
    data: undefined,
  },
  seccDetails: {
    data: {},
  },
  authDetails: {
    data: {
      email: undefined,
      group: undefined,
      companyType: undefined,
      company: undefined,
      role: undefined,
    }
  },
  configs : {
    data: undefined
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATA: {
      const { area, data } = action.payload;
      return {
        ...state,
        [area]: {
          ...state[area],
          data: data
        }
      };
    }

    case CLEAR_DATA: {
      const { area } = action.payload;
      return {
        ...state,
        [area]: {
          ...state[area],
          data: {}
        }
      };
    }

    case ADD_TODO: {
      const { id, content } = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, id],
        byIds: {
          ...state.byIds,
          [id]: {
            content,
            completed: false
          }
        }
      };
    }
    case TOGGLE_TODO: {
      const { id } = action.payload;
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [id]: {
            ...state.byIds[id],
            completed: !state.byIds[id].completed
          }
        }
      };
    }

    case SET_FILTER: {
      const { area, filters } = action.payload;
      return {
        ...state,
        [area]: {
          ...state[area],
          filters: filters
        }
      };
    }
    default:
      return state;
  }
}
